import { calculateSlideSize } from 'SlideShow/Utils';
import Effect from 'SlideShow/Effect';
import { restoreSlideEditable } from 'SlideShow/SlideShowEdit';

require('mediaelement');

export default class Slide {
  constructor(obj_id, position, pdf_creation) {
    this.id = obj_id;
    this.position = position;
    this.effects = [];
    this.effects_track = 0;
    this.number_of_effects = 0;
    this.current = false;
    this.has_effect_to_fire_forward = false;
    this.has_effect_to_fire_reverse = false;
    this.pdf_creation = pdf_creation;
    this.getDOMElement = function() { return $(`#${this.id}`); };
    this.isHidden = function() { return this.getDOMElement().hasClass('hidden-slide'); };
  }

  init() {
    window.players = {};

    return new Promise((resolve) => {
      if (this.pdf_creation) {
        $(`#${this.id} iframe`).each((i, el) => {
          $(el).remove();
        });
      }

      $(`#${this.id} .has_fx`).each((i, el) => {
        this.parseFX(el, this);
      });

      this.number_of_effects = this.effects.length;
      this.has_effect_to_fire_forward = !!(this.number_of_effects > 0);

      const $element = this.getDOMElement();
      $element.attr('data-position', this.position);
      $element.css('visibility', 'hidden');
      $element.find('iframe').hide();

      // This has to be here because drag and drop doesn't initialize when there are no drag and drop interactions
      $(`#${this.id}.reset_counters`).on('click', (e) => {
        e.preventDefault();
        // reset counter tables and charts
        // the functions called here are from table-counter-min.js and chart.js hosted on CDN100
        const $counterTable = $('.counter_table');
        if ($counterTable.length) {
          initializeCounterTables();
        }

        if (counterGraphObjects.length) {
          counterGraphObjects.filter(n => n).forEach((graph) => { graph.reset(); graph.render(); });
        }
      });

      resolve(this);
    });
  }

  parseFX(el, slide) {
    const fx_regex = new RegExp('^fx_', 'g');
    const class_arr = $(el).attr('class').split(' ');

    for (let i = 0; i < class_arr.length; i++) {
      if (class_arr[i].match(fx_regex)) {
        const locate = parseInt(Slide.createEffect(class_arr[i]).position, 10);
        const effect_obj = Slide.createEffect(class_arr[i]);

        if (!slide.effects[locate - 1]) {
          slide.effects[locate - 1] = [];
        }
        effect_obj.target = `#${this.id} .${class_arr.join('.')}`;

        if (effect_obj.type === 'click_and_reveal' || effect_obj.type === 'click_and_reveal_delay') {
          $(effect_obj.target).css('visibility', 'hidden');
        }

        slide.effects[locate - 1].push(effect_obj);
      }
    }
  }

  static createEffect(effect) {
    const effect_object = new Effect();
    effect_object.position = parseInt(effect.match(/fx_(\d*)_/)[1], 10);
    effect_object.type = effect.match(/fx_\d*_(.*)/)[1];

    return effect_object;
  }

  fireEffect(direction) {
    let promise;
    if (this.effects[this.effects_track][0].active && direction === 'forward') {
      this.effects_track++;
    }
    for (let i = 0; i < this.effects[this.effects_track].length; i++) {
      switch (this.effects[this.effects_track][i].type) {
        case 'click_and_reveal':
          // basically, when we come here, the slide_track is already 'loaded' for the NEXT slide.
          // in order to UNDO the previous effect the slide track must move back one position to 'target' it

          if (direction === 'forward') {
            promise = $(this.effects[this.effects_track][i].target).css('visibility', 'visible').promise();
            this.effects[this.effects_track][i].setActive(true);
          }
          else {
            promise = $(this.effects[this.effects_track][i].target).css('visibility', 'hidden').promise();
            this.effects[this.effects_track][i].setActive(false);
          }
          break;
        case 'click_and_reveal_delay':
          if (direction === 'forward') {
            promise = $(this.effects[this.effects_track][i].target).css('visibility', 'visible').promise();
            this.effects[this.effects_track][i].setActive(true);
          }
          else {
            promise = $(this.effects[this.effects_track][i].target).css('visibility', 'hidden').promise();
            this.effects[this.effects_track][i].setActive(false);
          }
          break;
        case 'click_and_hide':
          if (direction === 'forward') {
            promise = $(this.effects[this.effects_track][i].target).css('visibility', 'hidden').promise();
            this.effects[this.effects_track][i].setActive(true);
          }
          else {
            promise = $(this.effects[this.effects_track][i].target).css('visibility', 'visible').promise();
            this.effects[this.effects_track][i].setActive(false);
          }
          break;
        case 'click_and_hide_delay':
          if (direction === 'forward') {
            promise = $(this.effects[this.effects_track][i].target).delay(100).css('visibility', 'hidden').promise();
            this.effects[this.effects_track][i].setActive(true);
          }
          else {
            promise = $(this.effects[this.effects_track][i].target).css('visibility', 'visible').promise();
            this.effects[this.effects_track][i].setActive(false);
          }
          break;
        default:
          break;
      }
    }

    if (direction === 'forward') {
      this.has_effect_to_fire_reverse = true;
      if (this.effects_track < this.number_of_effects - 1) {
        this.effects_track++;
        this.has_effect_to_fire_forward = true;
      }
      else {
        this.has_effect_to_fire_forward = false;
      }
    }
    else if (direction === 'reverse') {
      this.has_effect_to_fire_forward = true;

      if (this.effects_track > 0) {
        this.effects_track--;

        this.has_effect_to_fire_reverse = true;
      }
      else {
        this.has_effect_to_fire_reverse = false;
      }
    }

    return promise;
  }

  makeSlideCurrent(setting, hasAnimationsEnabled) {
    const $slide_container = $(`#${this.id}`);
    const id = parseInt(this.id.split('_')[2], 10);
    if (setting) {
      if (window.carousel && window.slideToggle) {
        window.carousel.selectItem(id);
        window.carousel._centerItem(parseInt($(`#slide_${id}`).attr('data-position'), 10));
        window.slideToggle.setDOMStates();
        window.slideToggle.setPresentButtonHref();
      }

      $slide_container.stop(true, true).css('visibility', 'visible');
      $slide_container.stop(true, true).addClass('current-slide');
      restoreSlideEditable();

      $slide_container.find('iframe').show();

      // loading iframe here if it has data-src
      const $iframes = $slide_container.find('iframe');
      $iframes.each((index, iframe_el) => {
        const $iframe_el = $(iframe_el);
        if ($iframe_el.attr('data-src')) {
          $iframe_el.attr('src', $iframe_el.attr('data-src'));
        }
      });

      $slide_container.find('audio.aws_audio').mediaelementplayer({ audioWidth: 400 });
      $slide_container.find('video.aws_video').each((i, el) => {
        window.players[i] = $(el).mediaelementplayer({
          features: ['playpause', 'progress', 'current', 'duration', 'tracks', 'volume'],
          poster: el.getAttribute('poster')
        });
      });

      const slide_size = calculateSlideSize(screen.width, screen.height, true);

      Slide.setVideoSizes(slide_size);

      $(`#slide_note_${id}`).show();

      Slide.showSlideTags(id);

      $('.jump_to_slide').val(this.position + 1);

      if ((this.effects.length > 0) && !hasAnimationsEnabled) {
        this.syncFX();
      }
    }
    else {
      $slide_container.stop(true, true).css('visibility', 'hidden');
      $slide_container.stop(true, true).removeClass('current-slide');
      // reset visibility of animated elements when leaving a slide
      if (!$('#toggle_animations').is(':checked')) {
        $(`#${this.id}`).find('.has_fx').each(function() {
          this.style.visibility = 'hidden';
        });
      }

      $(`#slide_note_${id}`).hide();
      $(`#slide_tags_${id}`).hide();

      if ($slide_container.find('iframe').length) {
        Slide.stopVideoOnPage($(`#${this.id}`).find('iframe').attr('id'));
      }

      $slide_container.find('iframe').hide();
    }
    this.current = setting;
  }

  static showSlideTags(slideId) {
    const $slideTagsContainer = $(`#slide_tags_${slideId}`).show();
    const $slideTagsIframe = $slideTagsContainer.find('iframe.slide-tags-iframe');
    if ($slideTagsIframe.attr('src') !== undefined) return;

    const iframeSrc = $slideTagsContainer.data('iframe-src');
    $slideTagsIframe.attr('src', iframeSrc);
  }

  /**
   *  Shows/hides elements on a previously viewed slide according to their
   *  progress through the slide effects track.
   */
  syncFX() {
    if (this.effects.length === 0) {
      return;
    }

    for (let i = 0; i < this.number_of_effects; i++) {
      for (let j = 0; j < this.effects[i].length; j++) {
        const current_fx = this.effects[i][j];
        // current_fx.target is just a jquery selector, can return multiple elements
        const $elements = $(current_fx.target);

        $elements.each((_, el) => {
          const $el = $(el);

          // active effects
          if (current_fx.active) {
            // make click and reveals visible
            if (current_fx.type === 'click_and_reveal' || current_fx.type === 'click_and_reveal_delay') {
              $el.css('visibility', 'visible');
            }

            // make click and hides hidden
            if (current_fx.type === 'click_and_hide' || current_fx.type === 'click_and_hide_delay') {
              $el.css('visibility', 'hidden');
            }
          }

          // inactive effects
          // remove visibility from click and hide, but not elements with both click and hide / click and reveal
          else if ((current_fx.type === 'click_and_hide' || current_fx.type === 'click_and_hide_delay') && !$el.is('[class*="click_and_reveal"]')) {
            $el.css('visibility', 'visible');
          }
          else if (current_fx.type === 'click_and_reveal' ||
            current_fx.type === 'click_and_reveal_delay') {
            $el.css('visibility', 'hidden');
          }
        });
      }
    }
  }

  static setVideoSizes(slide_size) {
    let $current_player_node;
    let multiplier;
    let new_width;
    let new_height;

    Object.keys(window.mejs.players).forEach((player) => {
      new_width = undefined;
      new_height = undefined;
      $current_player_node = undefined;
      $current_player_node = $(window.mejs.players[player].node);

      if ($current_player_node.data('large-video-width') !== undefined) {
        new_width = $current_player_node.data('large-video-width');
      }
      else {
        multiplier = slide_size.width / window.slide_show.PREVIEW_WIDTH;
        new_width = ($current_player_node.width() * multiplier).toFixed(2);
        $current_player_node.data('large-video-width', new_width);
        $current_player_node.data('default-video-width', $current_player_node.width());
      }

      if ($current_player_node.data('large-video-height') !== undefined) {
        new_height = $current_player_node.data('large-video-height');
      }
      else {
        multiplier = slide_size.height / window.slide_show.PREVIEW_HEIGHT;
        new_height = ($current_player_node.height() * multiplier).toFixed(2);
        $current_player_node.data('large-video-height', new_height);
        $current_player_node.data('default-video-height', $current_player_node.height());
      }
    });
  }

  static stopVideoOnPage(id) {
    let url;
    let data;
    const frame = document.getElementById(id);


    if (frame) {
      url = frame.src;
      data = {
        method: 'pause',
        action: true
      };

      frame.contentWindow.postMessage(JSON.stringify(data), url);
    }
  }
}
