define([], function SlideShowEdit() {
  var edited_elements = {};
  var edit_button_html = '<div class="edit-button-wrapper"><span class="edit"> <i class="fa fa-edit"></i> &nbsp;Edit</span></div>';

  function getCurrentSlide() {
    return $('.current-slide')
  }

  function addEditButtons() {
    const editWrapper = $('.edit_slide.slide-edit-header');
    $(editWrapper).prepend(edit_button_html);
    setUpListeners();
  }

  function getAllEditedSlides() {
    return $('.edit-mode');
  }

  function getEditableElements(target) {
    if (target === undefined) {
      target = getCurrentSlide();
    }

    return $(target).find('p, li').not('.model-resource-html-body p').not('.model-resource-html-body li');
  }

  function saveTeardown() {
    toggleButton(false);
    exitEditMode(getAllEditedSlides());
    removeGUIEditableElements();
    getAllEditedSlides().removeClass('edit-mode').data("dirty", false);
  }

  function exitEditMode($target) {
    if ($target === undefined) {
      $target = getCurrentSlide()
    }
    $target.data("dirty", false);
    var editable_elements = getEditableElements($target);
    editable_elements.removeClass('editable');
    editable_elements.removeAttr('contenteditable');
  }

  function cancel() {
    undoChanges();
    exitEditMode();
    getCurrentSlide().removeClass('edit-mode');
  }

  function undoChanges() {
      if (getGUIEditableElements().length) {
          removeForeignObjects();
          displayTextElements();
      }

    var $changed_elems = $('.edited');
    $changed_elems.each(function() {
      var tag_name = this.tagName.toLowerCase();
      var identifier = tag_name+ "," + $('.current-slide '+tag_name).index($(this));
      var original_content = edited_elements[identifier];
      $(this).html(original_content);
    });
  }

  function removeForeignObjects() {
      getCurrentSlide().find('foreignObject').each(function() {
          $(this).remove();
      });
  }

  function removeGUIEditableElements() {
      getGUIEditableElements().each(function() {
          $(this).remove();
      });
  }

  function getGUIEditableElements() {
      return getCurrentSlide().find('text');
  }

  function displayTextElements() {
      getGUIEditableElements().each(function() {
          $(this).show();
          $(this).removeClass('toRemove')
      });
  }
  function toggleButton(editable) {
    if(editable) {
      enableEditMode();
    } else {
      disableEditMode();
    }
  }

  function restoreSlideEditable() {
      if (!editButtonsPresent() && userCanEdit()) {
          addEditButtons();
          restoreEditingState();
          $('.tooltip_wrapper').show();
      }
      else if (editButtonsPresent()) {
          restoreEditingState();
      }
  }

  function userCanEdit() {
    return $('.can-edit').length;
  }

  function editButtonsPresent() {
    return $('.edit-button-wrapper').find('.js-slide-edit-btn, .js-slide-save-btn').length;
  }

  function onGUI() {
    return (getCurrentSlide().attr('data-content-type') === 'gui');
  }

  function restoreEditingState() {
    if (getCurrentSlide().hasClass('edit-mode')) {
      enableEditMode();
    }
    else {
      disableEditMode();
    }
  }

  function enableEditMode() {
    $('.edit-button-wrapper').html('<button class="btn btn--sm btn--link-green js-slide-save-btn" id="save-slide">' +
                                   '<i class="fa fa-check" aria-hidden="true"></i><span>&nbsp;Save</span></button>' +
                                   '<button class="btn btn--sm btn--link-red js-slide-cancel-btn">' +
                                   '<i class="fa fa-times" aria-hidden="true"></i><span>&nbsp;Cancel</span></button>');
  }

  function disableEditMode() {
    $('.edit-button-wrapper').html('<button class="btn btn--sm btn--link-purple js-slide-edit-btn" id="edit-slide">' +
                                   '<i class="fa fa-edit" aria-hidden="true"></i><span>&nbsp;Edit Text</span></button>');
  }

  function setUpListeners() {
    const $edit_slide = $('.edit_slide');

    $edit_slide.on('click', '.js-slide-edit-btn', function() {
      toggleButton(true);
      edit();
    });

    $edit_slide.on('click', '.js-slide-cancel-btn', function() {
      toggleButton(false);
      cancel();
    });

    $edit_slide.on('click', '.js-slide-save-btn', function() {
      window.slide_show.save();
      if (!window.slideToggle.tci) {
        saveTeardown();
      }
    });

    $('.preview').on('input', '.current-slide p, .current-slide li', function(e) {
      $(e.target).addClass('edited');
    });
  }

  function copyElementToForeignObject(index,elem) {
      var foreign_obj = document.createElementNS('http://www.w3.org/2000/svg','foreignObject');
      $(elem).after(foreign_obj);

      var attributes = $(elem).prop('attributes');
      $.each(attributes, function() {
          $(foreign_obj).attr(this.name, this.value);
      });

      $(foreign_obj).addClass('added');

      var $svg_elem = getCurrentSlide().find('svg');

      var svg_offset = $svg_elem.offset();
      var elem_offset = $(elem).offset();
      var elem_svg_position = {top: elem_offset.top - svg_offset.top, left: elem_offset.left - svg_offset.left};

      const elementWidth = $(elem).get(0).getBoundingClientRect().width;
      const elementHeight = $(elem).get(0).getBoundingClientRect().height;

      const newWidth = elementWidth / ($svg_elem.width());
      const newHeight = elementHeight / ($svg_elem.height());
      var new_x = elem_svg_position.left/($svg_elem.width());
      var new_y = elem_svg_position.top/($svg_elem.height());

      //width tolerance to ensure that each line of text fits within the width
      //so there is no undesired wrapping
      const widthTolerance = 2;
      $(foreign_obj).attr('width', `${newWidth * 100 + widthTolerance}%`);
      $(foreign_obj).attr('height', `${newHeight * 100}%`);
      $(foreign_obj).attr('x', new_x*100 + '%');
      $(foreign_obj).attr('y', new_y*100 + '%');

      var p_tag = document.createElement('p');
      $(p_tag).attr('xmlns','http://www.w3.org/1999/xhtml');
      $(foreign_obj).append(p_tag);

      $(elem).find('tspan').each(function() {
              $(p_tag).append(document.createTextNode($(this).text() + ' '));
              $(p_tag).append('<br>')
      });

      $(elem).hide();
      $(elem).addClass('toRemove');
      var text_index = 'text,' + $('.current-slide text').index($(this));
      var foreign_index = 'add,' + $('.current-slide text').index($(this));
      $(elem).attr('index', text_index);
      $(foreign_obj).attr('index', foreign_index);
  }

  function convertGUITextToHTML() {
      var $text_elements = getGUIEditableElements();
      $.each($text_elements, copyElementToForeignObject);
  }

  function edit() {
    getCurrentSlide().addClass('edit-mode').data('dirty', 'true');
    if (getGUIEditableElements().length) {
      convertGUITextToHTML();
    }

    var editable_elements = getEditableElements();
    editable_elements.addClass('editable');
    editable_elements.attr('contenteditable', true);
    editable_elements.each(function () {

      var element_index; //add index
      if (this.tagName === 'P') {
        element_index = 'p,'+ $('.current-slide p').index($(this));
      }
      else if (this.tagName ==='LI') {
        element_index = 'li,' + $('.current-slide li').index($(this));
      }

      edited_elements[element_index] = $(this).clone(true).contents();
      $(this).attr('index', element_index);
    });

    $('p[contenteditable], li[contenteditable]').keydown(function(e) {
      if (e.keyCode === 13) {
        document.execCommand('insertHTML', false, '<br /><br />');
        return false;
      }
    });
  }

  return {
    restoreSlideEditable: restoreSlideEditable
  };
});
