define(['Modal'], function(Modal) {
  var Cloner = function(config) {
    this.id = config.id;
    this.type = config.type;
    this.slideshow = config.slideshow;
    this.trigger = config.trigger;
    this.popup = config.popup;
  };

  Cloner.prototype = {
    init: function() {
      this._buildModal();
      this._initModal();
    },

    _buildModal: function() {
      this.modal = this.modal || new Modal({
        default_closed: true,
        trigger_element: this.trigger,
        popup_element: this.popup,
        popup_class: 'modal_popup_wrapper grey-modal',
        close_button_class: 'modal_close_button',
        overlay: true,
        center: true,
        width: '700px',
        title: 'Save New Presentation'
      });

      this.modal.initialized ? this.modal.open() : this.modal.init();
    },

    _initModal: function() {
      $(this.popup).find(`form.clone_${this.type}`).on('submit', function(e) {
        e.preventDefault();
        this._clone();
      }.bind(this));
    },

    _clone: function() {
      $.ajax({
        url: `slide_shows/${this.type === 'slide_show' ? this.id : this.slideshow}/clone`,
        method: 'PUT',
        dataType: 'json',
        data: {
          module_id: this.type === 'lesson_module' ? this.id : undefined,
          from: 'index',
          slide: { title: $(this.modal.popup_element).find('form input[name="slide[title]"]').val() }
        }
      })
        .done(function(data) {
          if (data.redirect_to) window.location.replace(data.redirect_to);
        })
        .fail(function() {
          console.log('failure :(');
        })
    }
  };

  return Cloner
});