export function calculateSlideSize(width, height, return_only = false) {
  if ((width / height) < 1.72) {
    if (!return_only) {
      $('#slide_reel').css({
        width: width,
        height: Math.round(width / 1.72)
      });
    }

    return {
      height: Math.round(width / 1.72),
      width: width
    };
  }

  if (!return_only) {
    $('#slide_reel').css({
      height: height,
      width: Math.round(height * 1.72)
    });
  }

  return {
    height: height,
    width: Math.round(height * 1.72)
  };
}

export function getNextVisibleSlide(startingPosition, selectedPosition, reverse) {
  if (window.carousel && window.slideToggle) {
    const hiddenPositions = window.slideToggle.getHiddenPositions();
    const length = window.carousel.items.length;
    // if the selected slide is not hidden & is in a valid position
    if ($.inArray(selectedPosition, hiddenPositions) === -1 && selectedPosition <= length && selectedPosition > 0) {
      return selectedPosition - hiddenPositions.reduce(function(prev, curr) {
        return selectedPosition >= curr ? prev + 1 : prev;
      }, 0);
    }
    // if we have exceeded the length of the slide show, recurse downwards from starting position
    else if (selectedPosition > length) return getNextVisibleSlide(startingPosition, startingPosition, true);
    // recurse downwards
    else if (reverse && selectedPosition > 0) return getNextVisibleSlide(startingPosition, selectedPosition - 1, true);
    // recurse upwards
    else if (!reverse) return getNextVisibleSlide(startingPosition, selectedPosition + 1, false);
    return null;
  }
  return null;
}
