define([], function() {
  var overlay,
      popup,
      close,
      video,
      mediaelement,
      DEFAULT_WIDTH = 960,
      width = DEFAULT_WIDTH,
      DEFAULT_HEIGHT = 540,
      height = DEFAULT_HEIGHT,
      DEFAULT_TOP = 200,
      open = false,
      MARGIN_PERCENT = .05;


  /**
   * Build all DOM elements
   * @returns {HTMLElement}
   * @private
   */

  function _buildElements() {
    let $slide_window = $('#slide_window');

    overlay = document.createElement('div');
    overlay.className = "modal_overlay tci_video_popup_close_js";

    popup = document.createElement('div');
    popup.className = "modal_video_container";

    if ($slide_window.length) {
      $slide_window.append(overlay);
      $slide_window.append(popup);
    }
    else {
      document.body.appendChild(overlay);
      document.body.appendChild(popup);
    }

    close = document.createElement('div');
    close.className = "modal_close tci_video_popup_close_js";
    close.innerHTML = "X";
    popup.appendChild(close);

    return popup;
  }



  /**
   * Add event listeners to links and window resize
   * @private
   */

  function _setupListeners() {
    $('.tci_video_popup_js').on('click', function(e) {
      e.preventDefault();
      if ($(this).attr('data-s3-url')) {
        _showPopup();
        _loadS3Video($(this).attr('data-s3-url'),$(this).attr('data-caption-url'));
      }
      else {
        _loadVimeoVideo($(this).attr('data-vimeoid'));
        _showPopup();
      }
      _centerPopupV();
    });

    $(window).on('resize', function() {
      if (open) {
        _centerPopupH();
      }
    });

    $('.tci_video_popup_close_js').on('click', function(e) {
      e.preventDefault();
      _hidePopup();
    });

    return true;
  }

  /**
   * Load correct video from passed ID
   * @param id
   * @returns {HTMLElement}
   * @private
   */

  function _loadVimeoVideo(id) {
    video = document.createElement('iframe');
    video.className = "modal_video_iframe";
    video.src = "https://player.vimeo.com/video/" + id + "?autoplay=1&fun=0";
    video.width = width;
    video.height = height;

    popup.appendChild(video);

    return video;
  }

  /**
   * Load correct video from passed url and english caption from caption url
   * @param url, caption_url
   * @returns {HTMLElement}
   * @private
   */

  function _loadS3Video(url, caption_url) {
    if ($(popup).children('.mejs__container').length === 0) {
      video = document.createElement('video');
      video.className = "aws_video";
      video.src = url;

      video.style.width = '100%';
      video.style.height = '100%';
      video.preload = 'metadata';
      video.setAttribute('width', '100%');
      video.setAttribute('height', '100%');
      video.setAttribute('controls', '');

      if (caption_url) {
          var caption = _createTrackTag(caption_url);
          video.crossOrigin = "anonymous";
          video.appendChild(caption);
      }

      popup.appendChild(video);
      $(video).mediaelementplayer({
        features: ['playpause','progress','current','duration','tracks','volume'],
        success: function(media, node, player) {
          mediaelement = player;
        }
      });

      return video;
    }

  }

  /**
   * Create english caption tag from passed url
   * @param src
   * @returns {HTMLElement}
   * @private
   */
  function _createTrackTag(src)
  {
    var caption = document.createElement('track');
    caption.label = 'English';
    caption.kind = 'subtitles';
    caption.srclang = "en";
    caption.src = src;
    caption.setAttribute("default", "");

    return caption;
  }

  /**
   * Center the popup horizontally
   * @returns {boolean}
   * @private
   */

  function _centerPopupH() {
    popup.style.left = ($(window).width() - width) / 2 + "px";

    return true;
  }


  /**
   * Center the popup vertically based on parent height
   * @param parent_height
   * @returns {boolean}
   * @private
   */

  function _centerPopupV() {
    // Right now, we aren't constraining the video popup to its parent's height
    // If we want to do that, we should pass a second argument to account for it.
    popup.style.top = ($(window).innerHeight() - height) / 2 + "px";

    return true;
  }


  /**
   * Show the popup
   * @returns {boolean}
   * @private
   */

  function _showPopup() {
    _centerPopupH();

    if (mediaelement) {
      mediaelement.setPlayerSize($(popup).innerWidth(), $(popup).innerHeight());
      mediaelement.setControlsSize();
    }
    overlay.style.display = 'block';
    popup.style.display = 'block';
    open = true;

    return true;
  }


  /**
   * Hide the popup
   * @returns {boolean}
   * @private
   */

  function _hidePopup() {
    overlay.style.display = 'none';
    popup.style.display = 'none';

    if (video.className == 'aws_video') {
      $(popup).children('.mejs__offscreen').remove();
      $(popup).children('.mejs__container').remove();
    }
    else popup.removeChild(video);

    open = false;

    return true;
  }

  return {
    init: function() {
      _buildElements();
      _setupListeners();
    },

    setFullScreen: function(slide_size) {
      if (popup === undefined) {
        return
      }
      /** Width factors in additional space for margin */
      width = Math.round(slide_size.width - MARGIN_PERCENT * slide_size.width);
      height = Math.round(width * DEFAULT_HEIGHT / DEFAULT_WIDTH);

      popup.style.width = width + "px";
      popup.style.height = height + "px";
    },

    unsetFullScreen: function() {
      if (popup === undefined) {
        return
      }
      
      width = DEFAULT_WIDTH;
      height = DEFAULT_HEIGHT;

      popup.style.top = DEFAULT_TOP + "px";
      popup.style.width = DEFAULT_WIDTH + "px";
      popup.style.height = DEFAULT_HEIGHT + "px";

      _centerPopupH();
    }
  }
});