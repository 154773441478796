export default class Effect {
  constructor() {
    this.type = '';
    this.position = '';
    this.target = '';
    this.active = false;
  }

  setActive(set) {
    this.active = set;
  }
}
