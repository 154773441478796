const tippy = require('TciTippy').default;

define([], function() {
  function SlideshowDraw() {
    this.drawing_tool_selected = false;
    this.paint = false;
    this.preview_height = 0;
    this.preview_width = 0;
    this.onTop = false;
    this.empty = true;
  }

  SlideshowDraw.prototype = {

    init: function(preview_width, preview_height) {
      var this_obj = this;
      var x = 0; var y = 0;

      this.preview_height = preview_height;
      this.preview_width = preview_width;
      this.canvas = document.getElementById('slide_show_canvas');
      this.context = this.canvas.getContext('2d');
      this.context.lineWidth = 3;


//        Event listeners
      $("#slide_draw").on('click', this.toggleDrawingTool.bind(this));

      $("#slide_clear").on('click', this.clearCanvas.bind(this));

      this.canvas.onselectstart = function() {
        return false;
      };

      this.canvas.onmousedown = function() {
        return false;
      };

      $(this.canvas).on('mousedown touchstart', function(e) {
        var offset = $(this_obj.canvas).offset();
        var offset_left = offset.left;
        var offset_top = offset.top;
        this_obj.draw_height = $('#slide_reel').height();
        this_obj.draw_width = $('#slide_reel').width();
        x = e.pageX - offset_left;
        y = e.pageY - offset_top;
        this_obj.paint = true;
      });

      $(this.canvas).on('mousemove touchmove', function(e) {
        e.preventDefault();

        var offset = $(this_obj.canvas).offset();
        var offset_left = offset.left;
        var offset_top = offset.top;
        if (this_obj.paint == true && this_obj.drawing_tool_selected == true) {
          this_obj.context.beginPath();
          this_obj.context.moveTo(x, y);
          this_obj.context.lineCap = 'round';
          x = (e.pageX ? e.pageX : e.originalEvent.touches[0].pageX) - offset_left;
          y = (e.pageY ? e.pageY : e.originalEvent.touches[0].pageY) - offset_top;
          this_obj.context.lineTo(x, y);
          this_obj.context.stroke();
          this_obj.empty = false;
        }
      });

      $(document).on('mouseup touchend', function() {
        this_obj.paint = false;
      });

      $(this_obj.canvas).mouseout(function() {
        this_obj.paint = false
      });

      const slideWindow = document.querySelector('#slide_window');

      this.drawTooltip = tippy.default('#slide_draw', {
        appendTo: slideWindow,
        content: 'Draw On Slide'
      }).instances[0];

      tippy.default('#slide_clear', { appendTo: slideWindow, content: 'Clear Drawing' });
    },

    toggleDrawingTool: function() {
      if (this.onTop) {
        this.unsetDrawingTool();
      }
      else {
        this.setDrawingTool();
      }
    },

    setDrawingTool: function() {
      this.drawing_tool_selected = true;
      this.bringToFront(true);
      $(this.canvas).css('cursor', 'url("/images/pen_cursor.cur"), pointer');
      $("#slide_draw").addClass('focused');
      this.drawTooltip.setContent('Hide Drawing');
    },

    unsetDrawingTool: function() {
      this.drawing_tool_selected = false;
      this.bringToFront(false);
      $(this.canvas).css({ 'cursor' : 'default' });
      $("#slide_draw").removeClass('focused');
      this.drawTooltip.setContent('Draw On Slide');
    },

    bringToFront: function(value) {
      this.onTop = value;
      var z_index = value ? 15 : 5;
      $(this.canvas).css('z-index', z_index);
    },

    clearCanvas: function() {
      this.context.clearRect(0,0, $(this.canvas).width(), $(this.canvas).height());
      this.empty = true;
    },

    resizeCanvas: function() {
      if (this.canvas.width == $('#slide_reel').width() && this.canvas.height == $('#slide_reel').height()) {
        return;
      }
      var temp_image_data = this.context.getImageData(0, 0, this.canvas.width, this.canvas.height);
      var scale_change = ($('#slide_reel').width() - this.canvas.width) / this.canvas.width;


      // A negative scale change must be normalized, so we
      // add 1 and remove the negative for context.scale()
      var scale = scale_change + 1;

      this.canvas.width = $('#slide_reel').width();
      this.canvas.height = $('#slide_reel').height();

      var temp_canvas = document.createElement("canvas");
      temp_canvas.id = "temp_canvas";
      temp_canvas.height = temp_image_data.height;
      temp_canvas.width = temp_image_data.width;
      var temp_context = temp_canvas.getContext("2d");
      temp_context.putImageData(temp_image_data, 0, 0);

      this.context.save();
      this.context.scale(scale, scale);
      this.context.drawImage(temp_canvas, 0, 0);
      this.context.restore();
      this.context.lineWidth = 3 * scale;
    }
  };

  return SlideshowDraw
});

