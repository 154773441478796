define([
  'TCIUtils',
  'SlideShow/Slideshow',
  'SlideShow/SlideshowDraw',
  'SlideShow/slideShowGame',
  'SlideShow/Cloner'], function(TCIUtils, Slideshow, SlideshowDraw, slideShowGame, Cloner) {
  function init() {
    const $slideReel = $('#slide_reel');
    const $container = $('#slide_show_container');

    if ($slideReel.length) {
      replaceStudentTextLinks();
      replaceStudentNotebookLinks();
      replaceMaterialsLinks();
      if ($('.carousel').length === 0) triggerResizeEventOnPageReady();
      if ($slideReel.data('cache-bust')) cacheBustImages();
    }

    if ($('#slide_show_canvas').length) {
      window.slide_show_draw = new SlideshowDraw();
      window.slide_show = Slideshow.create({
        fullscreen_slide_track: $('#fullscreen_slide_track')[0],
        module: $container.data('module'),
        modulePosition: $container.data('module-position'),
        pdf_creation: false,
        responsive: !($container.hasClass('fixed')) && ($('#page_wrapper.full_width').length || $('#main').length),
        slide_show_draw: window.slide_show_draw,
        starting_slide: $container.data('starting-slide')
      });
      window.slide_show.init().then(function() {
        slideShowGame.slideShowGame();
      });
      window.slide_show_draw.init(window.slide_show.PREVIEW_WIDTH, window.slide_show.PREVIEW_HEIGHT);
    }
    else {
      window.slide_show = Slideshow.create({
        fullscreen_slide_track: $('#fullscreen_slide_track')[0],
        module: $container.data('module'),
        modulePosition: $container.data('module-position'),
        pdf_creation: true,
        slide_show_draw: null,
        starting_slide: $container.data('starting-slide')
      });
      window.slide_show.init();
    }
  }

  function initCloner() {
    const cloneButtons = $('[data-clone]');

    if (cloneButtons.length) {
      cloneButtons.each(function() {
        const type = this.dataset.clone;
        const cloner = new Cloner({
          id: this.dataset.id,
          popup: document.getElementById(`clone_${type}_${this.dataset.id}`),
          slideshow: this.dataset.slideshowId,
          trigger: this,
          type: type
        });
        cloner.init();
      });
    }
  }

  function replaceStudentTextLinks() {
    if ($('.student_text').length > 0 && $('a[data-studenttextlink]').length > 0) {
      const studentHref = $('.student_text').html();

      $('a[data-studenttextlink]').each(function() {
        $(this).attr('href', studentHref);
      });
    }
  }

  function replaceStudentNotebookLinks() {
    if ($('.student_text').length > 0 && $('a[data-studentnotebooklink]').length > 0) {
      const studentHref = $('.student_text').html();

      $('a[data-studentnotebooklink]').each(function() {
        $(this).attr('href', `${studentHref}#notebook`);
      });
    }
  }

  function replaceMaterialsLinks() {
    if ($('#slideshow_material_link').length > 0 && $('a[data-materialslink]').length > 0) {
      const studentHref = $('#slideshow_material_link').text();

      $('a[data-materialslink]').each(function() {
        $(this).attr('href', studentHref);
      });
    }
  }

  function cacheBustImages() {
    $('#slide_window img').each(function() {
      const oldSrc = $(this).attr('src');

      $(this).attr('src', TCIUtils.cacheBustString(oldSrc));
    });

    $('#slide_window image').each(function() {
      const oldSrc = $(this).attr('xlink:href');

      $(this).attr('xlink:href', TCIUtils.cacheBustString(oldSrc));
    });
  }

  function initializeVideoPlayer() {
    window.players = $('video.aws_video').mediaelementplayer({
      features: ['playpause', 'progress', 'current', 'duration', 'tracks', 'volume']
    });
  }

  function triggerResizeEventOnPageReady() {
    // need to trigger window resize to properly resize videos converted to html5 from mediaelement
    // when presenting a slide show in a new window
    $(document).ready(function() {
      $(window).trigger('resize');
    });
  }

  return {
    cacheBustImages: cacheBustImages,
    init: init,
    initCloner: initCloner,
    initializeVideoPlayer: initializeVideoPlayer,
    triggerResizeEventOnPageReady: triggerResizeEventOnPageReady,
  };
});
