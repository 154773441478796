const tippy = require('tippy.js');

define(['TCIUtils', 'TCIVideoPopup', 'SlideShow/Slide', 'SlideShow/Effect', 'Modal', 'Interactions/DragDropGame'], function(TCIUtils, TCIVideoPopup, Slide, Effect, Modal, DragDropGame) {
  var PREVIEW_WIDTH = 840,
    PREVIEW_HEIGHT = 487,
    drag_drop_games = [];

  function Slideshow(config) {
    this.slides = [];
    this.number_of_slides = 0;
    this.slide_track = 0;
    this.slide_show_draw = config.slide_show_draw;
    this.pdf_creation = config.pdf_creation || false;
    this.fullscreen_slide_track = config.fullscreen_slide_track;
    this.responsive = config.responsive;
    this.starting_slide = config.starting_slide;
    this.in_fullscreen = false;
    this.saving = false;
    this.PREVIEW_WIDTH = 840;
    this.PREVIEW_HEIGHT = 487;
    this.drag_drop_games = [];
    this.module = config.module;
    this.modulePosition = config.modulePosition;
  }

  Slideshow.prototype = {

    init: function () {
      var this_obj = this,
        position_count = 0,
        slide_promises = [];
      this.has_animations_disabled =  $('#toggle_animations').is(':checked');

      this.disable_fullscreen = !this.checkFullscreenApi();

      if ($('.save_container').length > 0) this.buildSaveModal();

      const slideWindow = document.querySelector('#slide_window');

      tippy.default('#enable_fullscreen', { appendTo: slideWindow, content: 'Present', });
      tippy.default('#exit_fullscreen', { appendTo: slideWindow, content: 'Exit Full Screen', });

      return new Promise(function(resolve, reject) {
        $('.slide').each(function () {
          var obj = new Slide.default($(this).attr('id'), position_count, this_obj.pdf_creation),
            game_type = $(this).find('.remember_origin_js').length > 0 ? 2 : 1;
          slide_promises.push(obj.init());
          if ($(this).find('.dd_answer_js').length > 0) {
            var new_game = DragDropGame.create($(this).attr('id'), game_type);
            new_game.init();
            drag_drop_games.push(new_game);
          }
          position_count++;
        });

        Promise.all(slide_promises).then(function(data) {
          resolve(this_obj);
          this_obj.slides = data;
          this_obj.number_of_slides = this_obj.slides.length;
          if (this.pdf_creation) {
          }
          else {
            this_obj.slides[0].makeSlideCurrent(true, this_obj.has_animations_disabled);
            this_obj.initScrubber();
            $(".number_of_slides").text(this_obj.slides.length);
            // Set up event listeners
            $(document).on('keydown', this_obj.handleKeyboardInput.bind(this_obj));
            $('.jump_to_slide').on('blur', function(e) { this.jumpToSlide($(e.target).val()); }.bind(this_obj));
            $('#enable_fullscreen').on('click', this_obj.enableFullScreenMode.bind(this_obj));
            $('#exit_fullscreen').on('click', this_obj.exitFullScreenMode.bind(this_obj));
            if ($('#toggle_animations').is(':checked')) {
              this_obj.set_final_state_elems();
            }

            const $lessonGuideNavbar = $('#slide_bottom_left');

            if (this_obj.disable_fullscreen) {
              this_obj.removeFullscreenButton();
            }
            else {
              $(document).on("MSFullscreenChange", function () {
                if (document.msFullscreenElement == null) {
                  this_obj.in_fullscreen = false;
                  resetToPreview();
                  TCIVideoPopup.unsetFullScreen();
                  $('#slide_reel').removeClass('fullscreen');

                  $lessonGuideNavbar.css({ opacity: '1', filter: 'alpha(opacity=100)' });
                }
                else {
                  this_obj.in_fullscreen = true;
                  if (this_obj.getCurrentSlide().isHidden()) {
                    this_obj.goToNextVisibleSlide('forward', true);
                  }

                  $lessonGuideNavbar.css({ opacity: '.3', filter: 'alpha(opacity=30)' });
                }
                this_obj.initScrubber();
                this_obj.setPositionText();
                $(window).trigger('resize');
              });

              $(document).on("fullscreenchange", function () {
                if (!document.fullscreen) {
                  this_obj.in_fullscreen = false;
                  resetToPreview();
                  TCIVideoPopup.unsetFullScreen();
                  $('#slide_reel').removeClass('fullscreen');

                  $lessonGuideNavbar.css({ opacity: '1', filter: 'alpha(opacity=100)' });
                }
                else {
                  this_obj.in_fullscreen = true;
                  if (this_obj.getCurrentSlide().isHidden()) {
                    this_obj.goToNextVisibleSlide('forward', true);
                  }

                  $lessonGuideNavbar.css({ opacity: '.3', filter: 'alpha(opacity=30)' });
                }
                this_obj.initScrubber();
                this_obj.setPositionText();
                $(window).trigger('resize');
              });

              $(document).on("mozfullscreenchange", function () {
                if (!document.mozFullScreen) {
                  this_obj.in_fullscreen = false;
                  resetToPreview();
                  TCIVideoPopup.unsetFullScreen();
                  $('#slide_reel').removeClass('fullscreen');

                  $lessonGuideNavbar.css({ opacity: '1', filter: 'alpha(opacity=100)' });
                }
                else {
                  this_obj.in_fullscreen = true;
                  if (this_obj.getCurrentSlide().isHidden()) {
                    this_obj.goToNextVisibleSlide('forward', true);
                  }

                  $lessonGuideNavbar.css({ opacity: '.3', filter: 'alpha(opacity=30)' });
                }
                this_obj.initScrubber();
                this_obj.setPositionText();
                $(window).trigger('resize');
              });

              $(document).on('webkitfullscreenchange', () => {
                if (!document.webkitIsFullScreen) {
                  slide_show.in_fullscreen = false;

                  resetToPreview();
                  TCIVideoPopup.unsetFullScreen();
                  $('#slide_reel').removeClass('fullscreen');

                  $lessonGuideNavbar.css({ opacity: '1', filter: 'alpha(opacity=100)' });
                }
                else {
                  this_obj.in_fullscreen = true;

                  if (this_obj.getCurrentSlide().isHidden()) {
                    this_obj.goToNextVisibleSlide('forward', true);
                  }

                  $lessonGuideNavbar.css({ opacity: '.3', filter: 'alpha(opacity=30)' });
                }
                this_obj.initScrubber();
                this_obj.setPositionText();
                $(window).trigger('resize');
              });
            }

            $('#next_slide').on('click', this_obj.next.bind(this_obj));
            $('#prev_slide').on('click', this_obj.previous.bind(this_obj));

            $('#toggle_animations').on('click', function() {
              this_obj.toggleAnimationFlag($(this).is(':checked'));
            });


            // Adjust opacity depending on whether user is mousing over/focusing
            const $lesson_guide_navbar = $('#slide_bottom_left');
            const $lesson_guide_navbar_focusable = $lesson_guide_navbar.find('.js-slide-navbar-focusable');

            $lesson_guide_navbar.on('mouseover', () => {
              if (this_obj.in_fullscreen) {
                $lesson_guide_navbar.css({ opacity: '1', filter: 'alpha(opacity=100)' });
              }
            });

            $lesson_guide_navbar_focusable.on('focus', function navbarFocus() {
              if (this_obj.in_fullscreen) {
                $lesson_guide_navbar.css({ opacity: '1', filter: 'alpha(opacity=100)' });
              }
            });

            $lesson_guide_navbar.on('mouseout', function navbarMouseout() {
              if (this_obj.in_fullscreen) {
                $lesson_guide_navbar.css({ opacity: '.3', filter: 'alpha(opacity=30)' });
              }
            });

            $lesson_guide_navbar_focusable.on('blur', function navbarBlur() {
              if (this_obj.in_fullscreen) {
                $lesson_guide_navbar.css({ opacity: '.3', filter: 'alpha(opacity=30)' });
              }
            });


            if (this_obj.responsive) {
              this_obj.resizeSlideShow();
              $(window).on('resize', this_obj.resizeSlideShow.bind(this_obj));
            }

            if (this_obj.starting_slide) {
              this_obj.slides[this_obj.slide_track].makeSlideCurrent(false, this_obj.has_animations_disabled);
              this_obj.slides[this_obj.starting_slide - 1].makeSlideCurrent(true, this_obj.has_animations_disabled);
              this_obj.slide_track = this_obj.starting_slide - 1;
              $('.position_slider').slider('value', this_obj.starting_slide);
              window.slide_show.fullscreen_slide_track.innerText = ` / ${this_obj.getSlideShowLength()}`;
            }
          }

          $('.jump-link').click((e) => {
            let slideNumber;

            if (!e.target.classList.contains('jump-link')) {
              slideNumber = $(e.target).parents('.jump-link').attr('data-jump-to');
            }
            else {
              slideNumber = $(e.target).attr('data-jump-to');
            }

            this_obj.jumpToSlide(slideNumber);
          });
        });
      });
    },

    toggleAnimationFlag: function(flag){
        if(flag){
            this.updateAnimationFlag(false);
            this.has_animations_disabled= true;
            this.set_final_state_elems()
        } else {
            this.updateAnimationFlag(true);
            this.has_animations_disabled = false;
            this.set_initial_state_elems()
            }
        },
    set_final_state_elems: function(){
        var $click_and_reveal = $("[class*='click_and_reveal']");
        var $click_and_hide   = $("[class*='click_and_hide']");

        $click_and_reveal.css('visibility', 'inherit');
        $click_and_reveal.css('position', 'relative');

        $click_and_hide.css('visibility', 'hidden');

    },

    set_initial_state_elems: function(){
        var $click_and_reveal = $("[class*='click_and_reveal']");
        var $click_and_hide   = $("[class*='click_and_hide']");

        $click_and_reveal.css('visibility', 'hidden');


        $click_and_hide.css('visibility', 'inherit');
        $click_and_reveal.css('position', 'relative');
    },

    updateAnimationFlag: function(has_animations_enabled) {

        var update_path = $('#toggle_animation_path').html();
        $.ajax({
            type : "POST",
            url: update_path,
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({has_animations_enabled: has_animations_enabled, id: this.id,})
        });
    },

    indexOfCurrentSlide: function() {
      return parseInt($('.slide.current-slide')[0].getAttribute('data-position'), 10);
    },

    moveToNewSlide: function (new_index) {
      let current_index = this.indexOfCurrentSlide();
      //if (new_index >= 0 && new_index <= this.visibleSlides().length) {
      if (new_index >= 0 && new_index <= slide_show.slides.length - 1) {
        //check if slide at new_index is last and hidden.//
        this.getCurrentSlide().makeSlideCurrent(false, this.has_animations_disabled);

        const nextActiveSlide = this.slides[new_index];
        nextActiveSlide.makeSlideCurrent(true, this.has_animations_disabled);

        const slideId = nextActiveSlide.id.match(/[0-9]+/g)[0];

        $('#slide_reel').attr('data-current-slide-id', slideId);

        const editSlideLink = document.querySelector('#edit-slide-link');

        if (editSlideLink) {
          editSlideLink.href = editSlideLink.href.replace(/slides\/[0-9]+/, `slides/${slideId}`);
        }

        $('.position_slider').slider('value', this.getCurrentPosition());
        this.setPositionText();
        if (this.in_fullscreen && this.getCurrentSlide().isHidden()) {
          this.goToNextVisibleSlide(new_index > current_index ? 'forward' : 'backward', false);
        }
        $(window).trigger('resize');
      }
    },

    /**
     * If the current slide is hidden, then make the next slide current instead.
     * If all subsequent slides are hidden, make the previous slide current instead.
     */
    goToNextVisibleSlide: function(direction, first_move) {
      let current_index = this.indexOfCurrentSlide(),
          next_index = current_index + (direction === 'forward' ? 1 : -1);

      if (window.slideToggle) {
        let all_hidden = window.slideToggle.hidden === slide_show.slides.length,
            current_slide = this.getCurrentSlide(),
            hidden_remaining = current_slide.getDOMElement().nextAll('.hidden-slide').length,
            slides_remaining = current_slide.getDOMElement().nextAll('.slide').length;
        if (all_hidden) return;
        else if ((slides_remaining === hidden_remaining && first_move)) {
          this.moveToNewSlide(current_index - 1);
        }
        // if we are on a hidden slide
        else if (this.getCurrentSlide().isHidden()) {
          // if we are on the first slide and it is hidden, move forward
          if (current_index === 0) { this.moveToNewSlide(current_index + 1); }

          // otherwise take direction argument into account
          else { this.moveToNewSlide(next_index); }
        }
        //if you are in fullscreen and the last slides are hidden do not go forward
        else if (slides_remaining === hidden_remaining && direction === 'forward') {
          return;
        }
        // if we are expected to move (not coming from full screen toggle) on a normal slide
        else {
          this.moveToNewSlide(next_index);
        }
      }
      else {
        this.moveToNewSlide(next_index);
      }
    },

    /**
     * Returns array without slide at given index
     * @param index
     */
    removeSlide: function(index) {
      return this.slides.slice(0, index).concat(this.slides.slice(index+1, this.slides.length));
    },

    /**
     * Returns array with the slide inserted at given index in given array
     * @param arr, new_index, slide
     */
    insertSlide: function(arr, new_index, slide) {
      return arr.slice(0, new_index).concat(slide).concat(arr.slice(new_index, arr.length));
    },

    reorder: function(slide_id, new_index) {
      var old_index = slide_show.slides.map(function(s) { return s.id; }).indexOf("s_slide_" + slide_id);

      // first, remove the slide to be reordered from the slides list
      // then insert slide at new index
      this.slides = this.insertSlide(this.removeSlide(old_index), new_index, this.slides[old_index]);

      // update slide positions based on array indicies
      this.slides.map(function(slide, index) {
        slide.position = index;
        slide.getDOMElement().attr('data-position', index);
      });

      window.slideToggle.dirty = true;
    },

    /**
     * Checks whether the user's browser has access to the fullscreen API.
     * If so, adds class `fullscreen` to the body and returns true
     * Else returns false
     * @returns {boolean}
     */

    checkFullscreenApi: function() {
      var body = $('body')[0];

      var isAvailable = (typeof body.msRequestFullscreen === 'function'  ||
      typeof body.requestFullscreen === 'function'    ||
      typeof body.mozRequestFullScreen === 'function' ||
      typeof body.webkitRequestFullScreen === 'function');

      if (isAvailable) body.classList.add('fullscreen');

      return isAvailable;
    },

    removeFullscreenButton: function() {
      $('#enable_fullscreen').remove();
      $('#exit_fullscreen').remove();
    },

    handleKeyboardInput: function (e) {
      if (!$('input.jump_to_slide, input , textarea, [contenteditable=true]').is(':focus')
        // firefox doesn't see contenteditable as focused here, so we can check
        // by looking at document.activeelement's contenteditable element as well
          && document.activeElement.contentEditable !== 'true') {
        if (e.keyCode === 39 || e.keyCode === 32 || e.keyCode === 34) this.next();
        else if (e.keyCode === 37 || e.keyCode === 33) this.previous();
      }
      else {
        if (e.keyCode === 13) this.jumpToSlide($(e.target).val());

      }
    },

    jumpToSlide: function(num) {
      let slide_num = num;
      if ((slide_num) > this.visibleSlides().length) {
        slide_num = this.visibleSlides().length;
      }
      else if (slide_num < 1 || slide_num == null) {
        slide_num = 1;
      }

      this.moveToNewSlide(this.visibleSlides()[slide_num - 1].position);

      if (this.slide_show_draw) this.slide_show_draw.clearCanvas();
    },

    next: function() {
      if (this.getCurrentSlide().has_effect_to_fire_forward && !this.has_animations_disabled) {
        this.getCurrentSlide().fireEffect("forward");
      }
      else {
        if (this.indexOfCurrentSlide() < this.number_of_slides - 1) {
          this.goToNextVisibleSlide('forward', false);
          if (this.slide_show_draw) this.slide_show_draw.clearCanvas();
        }
      }
    },

    previous: function() {
      if (this.getCurrentSlide().has_effect_to_fire_reverse && !this.has_animations_disabled) {
        this.getCurrentSlide().fireEffect("reverse");
      }
      else {
        if (this.indexOfCurrentSlide() > 0) {
          this.goToNextVisibleSlide('backward', false);
          if (this.slide_show_draw) this.slide_show_draw.clearCanvas();
        }
      }
    },

    getCurrentSlide: function() {
      var current_slide;
      this.slides.forEach(function(slide, _) {
        if (slide.current === true) current_slide = slide;
      });
      return current_slide;
    },

    slidesHiddenBeforeCurrent: function() {
      return this.getCurrentSlide().getDOMElement().prevAll('.hidden-slide').length;
    },

    /**
     * Returns the current position of the selected slide,
     * taking hidden slides into account
     */
    getCurrentPosition: function() {
      let hidden_before = this.slidesHiddenBeforeCurrent(),
        // slide's position is 0 indexed
        current_position = this.getCurrentSlide().position + 1;

      return (this.in_fullscreen && window.slideToggle) ? current_position - hidden_before : current_position
    },

    /**
     * Returns the length of the slide show,
     * taking hidden slides into account
     */
    getSlideShowLength: function() {
      return (this.in_fullscreen && window.slideToggle) ? this.number_of_slides - window.slideToggle.hidden.length : this.number_of_slides
    },

    /**
     * If we are in fullscreen, we need to take the hidden slides
     * into account when determining what slide we are on and how
     * many we have.
     */
    setPositionText: function() {
      var input = document.getElementsByClassName("jump_to_slide");
      input[1].value = `${slide_show.getCurrentPosition()}`;
      this.fullscreen_slide_track.innerText = ` / ${this.getSlideShowLength()}`;
    },

    resizeSlideShow: function() {
      var slide_size;
      if (!this.in_fullscreen && $('.site-banner').length > 0){
        slide_size = calculateSlideSize(this.PREVIEW_WIDTH, this.PREVIEW_HEIGHT);
      }
      else{
        var width = $(window).innerWidth();
        var height = $(window).innerHeight();
        slide_size = calculateSlideSize(width, height);
      }

      var containerHeight = $('#slide_reel').innerHeight();

      $('.slide_wrapper').css('height', containerHeight);

      if (this.slide_show_draw) this.slide_show_draw.resizeCanvas();

      resizeSlidePlayers(slide_size.width / this.PREVIEW_WIDTH);

      TCIVideoPopup.setFullScreen(slide_size);

      calculatePresentationFontSize(parseInt($('#slide_reel').height()));
    },

    enableFullScreenMode: function () {
      // don't allow fullscreen if all are hidden in the slide toggler
      if (window.slideToggle && window.slideToggle.hidden.length == this.slides.length) return;

      var docElm = document.getElementById('slide_window'),
          width = screen.width,
          height = screen.height,
          slide_size;

      slide_size = calculateSlideSize(width, height);

      resizeSlidePlayers(slide_size.width / this.PREVIEW_WIDTH);

      calculatePresentationFontSize(parseInt($('#slide_reel').height()));
      TCIVideoPopup.setFullScreen(slide_size);

      $('#slide_reel').addClass('fullscreen');

      if      (docElm.msRequestFullscreen)     docElm.msRequestFullscreen();
      else if (docElm.requestFullscreen)       docElm.requestFullscreen();
      else if (docElm.mozRequestFullScreen)    docElm.mozRequestFullScreen();
      else if (docElm.webkitRequestFullScreen) docElm.webkitRequestFullScreen();
      else {
        $('#slide_reel').removeClass('fullscreen');
        console.log("Could not go to full screen mode");
      }
      if (this.slide_show_draw) this.slide_show_draw.resizeCanvas();
      if (window.slideToggle && window.slideToggle.hiddenPositions.length > 0) {
        this.initScrubber();
      }
    },

    exitFullScreenMode: function() {
      if      (document.exitFullscreen)       document.exitFullscreen();
      else if (document.msExitFullscreen)     document.msExitFullscreen();
      else if (document.mozCancelFullScreen)  document.mozCancelFullScreen();
      else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
      this.initScrubber();
    },

    visibleSlides: function() {
      if (this.in_fullscreen) {
        return this.slides.reduce(function(acc, slide) {
          if (!slide.isHidden()) acc.push(slide); return acc;
        }, [])
      }
      else return this.slides;
    },

    initScrubber: function () {
      $(".position_slider").slider({
        value: this.getCurrentPosition(),
        min: 1,
        max: this.getSlideShowLength(),
        step: 1,
        range: 'min',
        slide: function (event, ui) {
          let movement_keycodes = [32, 33, 34, 37, 39];
          if (movement_keycodes.includes(event.keyCode)) event.preventDefault();
          else {
            event.preventDefault();
            let slide_number = ui.value;
            this.moveToNewSlide(this.visibleSlides()[slide_number - 1].position);
            if (this.slide_show_draw) { this.slide_show_draw.clearCanvas(); }
          }
        }.bind(this)
      });
    },

    buildSlideParams: function() {
      let params = { slide: {} };
      params = this.getSlideOrder(params);
      params = this.getHiddenSlides(params);
      params = this.getSlideShowTitle(params);
      params = this.getSlideChanges(params);
      params = this.getSlideNotes(params);
      params['module_id'] = this.module;
      params['current_slide'] = window.carousel.selected;
      return params;
    },

    getSlideOrder: function(params) {
      params.slide = params.slide || {};
      if (window.carousel.dirty) {
        params.slide.order = $(window.carousel.scrollable).sortable('toArray').map(function(id) {
          return id.split('_')[1];
        });
      }
      return params;
    },

    getHiddenSlides: function(params) {
      params.slide = params.slide || {};
      if (window.slideToggle.dirty) {
        // if we unhide all slides, send an array with an empty string
        params.slide.hidden = (window.slideToggle.hidden.length === 0) ? [''] : window.slideToggle.hidden;
      }
      return params;
    },

    getSlideShowTitle: function(params) {
      if (this.modal) {
        params.slide = params.slide || {};
        params.slide.title = $(this.modal.popup_element).find('form input[name="slide[title]"]').val();
      }

      return params;
    },

    /**
     * Iterates through all of the dirty text editors on the page
     * to build a slide params object in the following format:
     *
     *  slide: {
   *    teacher_notes: { id: 'teacher note', id2: 'teacher note 2'... }
   *    }
     *  }
     */
    getSlideNotes: function(params) {
      params.slide = params.slide || {};
      // filter for editors with changes
      return window.editors.filter(function(editor) {
        return editor.dirty;
        // build params object
      }).reduce(function(prev, curr) {
        const id = parseInt(curr.el.dataset.textEditor, 10);
        const teacherNote = curr.froala_editor.html.get();

        prev.slide.notes = prev.slide.notes || {};
        prev.slide.notes[id] = teacherNote;
        return prev;
      }, params);
    },

    getSlideChanges: function(params) {
      params.slide = params.slide || {};
      // filter for editors with changes
      return $('.edit-mode').toArray().reduce(function(acc, curr_slide) {
        var id = $(curr_slide).attr('id');
        var id_num = id.split('_');
        id_num = parseInt(id_num[id_num.length - 1]);
        var changes = $(curr_slide).find('.edited, .added, .toRemove').toArray().map(function(elem) {
          if ($(elem).hasClass('added')) {
              $(elem).removeClass('added');
              $(elem).find('p').removeClass('edited editable');
              return [$(elem).attr('index'),elem.outerHTML];
          }
          else{
              return [$(elem).attr('index'),$(elem).html().replace('&nbsp;', ' ')]
          }
        });
        acc.slide.slide_contents = acc.slide.slide_contents || {};
        acc.slide.slide_contents[id_num] = JSON.stringify(changes);
        return acc;
      }, params);
    },

    buildSaveModal: function() {
      this.modal = this.modal || new Modal({
        trigger_element: undefined,
        popup_element: $('.save_container')[0],
        popup_class: 'modal_popup_wrapper grey-modal',
        close_button_class: 'modal_close_button',
        overlay: true,
        center: true,
        width: '700px',
        title: 'Save New Presentation'
      });
    },

    initSaveModal: function() {
      $('form#save_slideshow').on('submit', function(e) {
        e.preventDefault();
        this.saveSlideShow();
      }.bind(this));

      this.modal.initialized ? this.modal.open() : this.modal.init();
    },

    save: function() {
      if ($('.save_container').length > 0) this.initSaveModal();
      else this.saveSlideShow();
    },

    saveSlideShow: function(params) {
      if (!this.saving) {
        this.saving = true;
        var slideParams = params || this.buildSlideParams();
        slideParams.locale = $('#slide_show_container').attr('data-locale');
        $(window.slideToggle.saveMessage).text('Saving…');

        $.ajax({
          url: window.slideToggle.tci ? 'clone' : 'update_slides',
          dataType: 'json',
          method: 'PUT',
          data: slideParams
        })
          // TODO: Try to move all of these callbacks into their respective modules
          .fail(function() {
            this.saving = false;
            $(window.slideToggle.saveMessage).html('<i class="fa fa-exclamation-circle"></i>&nbsp;Save failed. Please refresh the page and try again.');
            $(window.slideToggle.saveMessage).addClass('error');
          }.bind(this))
          .done(function (data) {
            this.saving = false;
            window.slideToggle.dirty = false;
            window.carousel.dirty = false;
            $(".slide").data("dirty", false);
            window.editors.forEach(function(editor) {
              editor.destroyEditor();
              editor.dirty = false;
              editor.edit_mode = false;
            });

            if (data.redirect_to) {
              window.location.replace(data.redirect_to);
            }

            const time = TCIUtils.getTime();
            let savedString = `Saved at ${time.current_hour}:${time.fixed_minutes} ${time.am_pm}`;
            savedString += time.timezone ? ` (${time.timezone})` : '';

            $(window.slideToggle.saveMessage).html(`<i class="fa fa-check"></i>&nbsp;${savedString}`);
            $(window.slideToggle.saveMessage).removeClass('disabled');
            $(window.slideToggle.saveMessage).removeClass('error');
            $(window.slideToggle.saveButton).addClass('disabled');
            $(window.slideToggle.saveButton).find('a').text('Save');
          }.bind(this));
      }
    }
  };

  function parseFX(el, slide) {
    var fx_regex = new RegExp('^fx_', 'g');
    var class_arr = $(el).attr('class').split(' ');

    for (var i = 0; i < class_arr.length; i++) {
      if (class_arr[i].match(fx_regex)) {
        var locate = parseInt(createEffect(class_arr[i]).position);
        var effect_obj;

        if (!slide.effects[locate - 1]) {
          slide.effects[locate - 1] = [];
        }
        effect_obj = createEffect(class_arr[i]);
        effect_obj.target = createEffectTarget(slide, class_arr);

        if (effect_obj.type == "click_and_reveal" || effect_obj.type == "click_and_reveal_delay") {
          $(effect_obj.target).css('visibility', 'hidden');
        }

        slide.effects[locate - 1].push(effect_obj);
      }
    }
  }

  function createEffect(effect) {
    var effect_object = new Effect();
    effect_object.position = parseInt(effect.match(/fx_(\d*)_/)[1]);
    effect_object.type = effect.match(/fx_\d*_(.*)/)[1];

    return effect_object;
  }

  function createEffectTarget(slide, class_arr) {
    return ("#" + slide.id + ' .' + class_arr.join('.'));
  }

  function calculateSlideSize(width, height, return_only) {
    return_only = (return_only || false);
    if ((width / height) < 1.72) {
      if (!return_only) {
        $('#slide_reel').css({
          "width": width,
          "height": Math.round(width / 1.72)//,
          // "margin-top": (height - Math.round(width / 1.72)) / 2 + "px"
        });
      }

      return {
        height: Math.round(width / 1.72),
        width: width
      }
    }
    else {
      if (!return_only) {
        $('#slide_reel').css({
          "height": height,
          "width": Math.round(height * 1.72)
        });
      }

      return {
        height: height,
        width: Math.round(height * 1.72)
      }
    }
  }

  function resizeSlidePlayers(multiplier) {
    var $current_player_node,
      new_width,
      new_height;

    for (var player in mejs.players) {
      $current_player_node = undefined;
      new_width = undefined;
      new_height = undefined;

      $current_player_node = $(mejs.players[player].node);

      new_width = $current_player_node.data('default-video-width') * multiplier;

      if (new_width == 0 || new_width === undefined) {
        new_width = 400;
      }

      if (mejs.players[player].isVideo) new_height = $current_player_node.data('default-video-height') * multiplier;
      else new_height = mejs.players[player].height;

      if (new_height == 0 ||  new_height === undefined) {
        new_height = 30;
      }

      $current_player_node.height(new_height);
      $current_player_node.width(new_width);

      mejs.players[player].media.setSize(new_width, new_height);
      mejs.players[player].setPlayerSize(new_width, new_height);
    }

    $('.react-video-player').each((index, videoPlayer) => {
      const width = videoPlayer.width * multiplier;
      $(videoPlayer).css('width', `${width}px`);
    });
  }

  function calculatePresentationFontSize(height) {

    /*
     * This number was determined by finding the right font size for 10 different
     * screen heights, and taking the mean of the ratios (height / font-size)
     */
    var size = Math.round(height / 35.54);

    $('#slide_window').css('font-size', size + "px");

    return size;
  }

  function resetToPreview() {
    $('#slide_window').css('font-size', '14px');
    $('#slide_reel').css({
      "height": this.PREVIEW_HEIGHT,
      "width": this.PREVIEW_WIDTH,
      "margin-top": "0px"
    });
  }

  return {
    create: function(config) {
      return new Slideshow(config);
    },
    calculateSlideSize: calculateSlideSize
  };
});
